import { Card } from '../card';

import { RequestLog } from './request-log';

interface Props {
  requestPayload?: { [key: string]: any } | undefined;
  responsePayload?: { [key: string]: any } | undefined;
  header: string;
  isCollapsed?: boolean;
}

export const RequestCard = ({
  header,
  requestPayload,
  responsePayload,
  isCollapsed = false,
}: Props) => {
  return (
    <Card header={header} isCollapsed={isCollapsed}>
      <RequestLog
        requestPayload={requestPayload}
        responsePayload={responsePayload}
      />
    </Card>
  );
};
