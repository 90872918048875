import AdyenCheckout from '@adyen/adyen-web';
import { PaymentMethodsResponseObject } from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types';

import { authorize } from '../../api/authorization';
import { getExecution } from '../../api/get-execution';
import {
  AUTHORIZE_WITH_ADYEN_DROPIN,
  withAuthorizeAmount,
} from '../../example-payloads/authorize';

interface Options {
  amount: string;
  clientKey: string;
  paymentMethodsResponse: PaymentMethodsResponseObject;
  bearer: string;
  executionId: string;
  baseUrl: string;
  hideCVC: boolean;
}

const ADYEN_CONFIG_TPL = {
  environment: 'test',
  locale: 'en-US',
  analytics: {
    enabled: false,
  },
  paymentMethodsConfiguration: {
    card: {
      hasHolderName: true,
      holderNameRequired: false,
      enableStoreDetails: true,
    },
    storedCard: {
      hideCVC: true,
    },
  },
};

const withCVC = (config: typeof ADYEN_CONFIG_TPL, hideCVC: boolean) => {
  return {
    ...config,
    paymentMethodsConfiguration: {
      ...config.paymentMethodsConfiguration,
      storedCard: {
        ...config.paymentMethodsConfiguration.storedCard,
        hideCVC,
      },
    },
  };
};

const createAdyenCheckout = async ({
  amount,
  clientKey,
  paymentMethodsResponse,
  bearer,
  executionId,
  baseUrl,
  hideCVC,
}: Options) => {
  return await AdyenCheckout({
    ...withCVC(ADYEN_CONFIG_TPL, hideCVC),
    clientKey,
    paymentMethodsResponse,
    onAdditionalDetails: (state: any, dropin: any) => {
      console.log(state, dropin);
      // Your function calling your server to make a `/payments/details` request
    },
    onSubmit: async (state: any, dropin: any) => {
      try {
        const payload = withAuthorizeAmount(
          {
            ...AUTHORIZE_WITH_ADYEN_DROPIN,
            paymentComposition: [
              {
                integrationType: 'adyenDropInThreeSteps',
                amount: {
                  value: amount,
                  currency: 'EUR',
                },
                paymentInstrumentData: {
                  providerData: state.data.paymentMethod,
                },
                storeInstrument: Boolean(state.data.storePaymentMethod),
              },
            ],
            returnInfo: {
              success: `${window.location.origin}/dropin-success?executionId=${executionId}`,
            },
          },
          amount
        );

        const authReq = await authorize(bearer, executionId, payload, baseUrl);

        if (!authReq.ok) {
          throw new Error('authorization failed');
        }

        const execReq = await getExecution(bearer, executionId, baseUrl);
        const execRes = await execReq.json();
        const statuses: Array<{ code: string }> = execRes.status;

        const finalStatuses = [
          'authorizePending',
          'authorizeSuccessful',
          'authorizeFailed',
        ];

        let result: {
          [key: string]: any;
        } = execRes;
        let finalState = statuses.find(({ code }) =>
          finalStatuses.includes(code)
        );

        if (!finalState) {
          const currentStatuses = statuses.map(({ code }) => code);
          const longPollReq = await getExecution(
            bearer,
            executionId,
            baseUrl,
            currentStatuses
          );
          const longPollRes = await longPollReq.json();

          finalState = longPollRes.status.find(({ code }: { code: string }) =>
            finalStatuses.includes(code)
          );
          result = longPollRes;
        }

        switch (finalState?.code) {
          case 'authorizeSuccessful':
            dropin.setStatus('success');
            break;
          case 'authorizePending':
            dropin.handleAction(result.links.confirm.action);
            break;
          default:
            dropin.setStatus('error');
            console.log(result);

            throw new Error('Unexpected execution state');
        }
      } catch (e) {
        console.log(e);
      }
    },
  });
};

export const createAdyenDropIn = async (options: Options) => {
  return (await createAdyenCheckout(options)).create('dropin');
};
