import { useCallback } from 'react';
import { ReactNode, useState } from 'react';

import { ConfigurationContext } from './context';
import { CONFIG_INITIAL_VALUES } from './fields';
import { ConfigurationObject } from './types';

interface Props {
  children: ReactNode;
}

export const ConfigurationProvider = ({ children }: Props) => {
  const [configuration, setConfigurationState] = useState<ConfigurationObject>(
    CONFIG_INITIAL_VALUES
  );

  const setConfiguration = useCallback(
    <TKey extends keyof ConfigurationObject>(key: TKey, value: any) => {
      setConfigurationState((config) => ({
        ...config,
        [key]: value,
      }));
      sessionStorage.setItem(key, JSON.stringify(value));
    },
    []
  );

  return (
    <ConfigurationContext.Provider value={[configuration, setConfiguration]}>
      {children}
    </ConfigurationContext.Provider>
  );
};
