export const withAuthorizeAmount = (payload: any, amount: string) => {
  const clone = JSON.parse(JSON.stringify(payload));
  clone.amount.value = amount;

  return clone;
};

export const AUTHORIZE_WITH_ADYEN_DROPIN = {
  amount: {
    value: '100',
    currency: 'EUR',
  },
  // Replace paymentComposition with adyen dropin data
  paymentComposition: [],
};

export const AUTHORIZE_WITH_GOOGLE_PAY = {
  ...AUTHORIZE_WITH_ADYEN_DROPIN,
  paymentComposition: [
    {
      paymentMethodCode: 'googlePay',
      integrationType: 'api',
      paymentInstrumentData: {
        paymentToken: 'token',
      },
      amount: {
        value: '100',
        currency: 'EUR',
      },
    },
  ],
  returnInfo: {
    success: 'test',
    cancel: 'test',
    error: 'test',
  },
};
