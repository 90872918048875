import { v4 } from 'uuid';

import { buildUrl } from './http-utils';

export const confirmPayment = (
  authHeader: string,
  executionId: string,
  redirectResult: string,
  baseUrl: string
) => {
  const url = buildUrl(
    baseUrl,
    `merchant/workflows/payment-acceptance/executions/${executionId}/confirm`
  );

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-idempotency-key': v4(),
      Authorization: authHeader,
    },
    body: JSON.stringify({
      data: {
        redirectResult,
      },
    }),
  });
};
