import QrScanner from 'qr-scanner';
import React, { useEffect, useRef } from 'react';

interface QrCodeScannerProps {
  isVisible?: boolean;
  onScan?: (result: string) => void;
}

export function QrCodeScanner({ onScan, isVisible }: QrCodeScannerProps) {
  const videoEl = useRef<HTMLVideoElement>(null);
  const scanner = useRef<QrScanner | null>(null);

  useEffect(() => {
    if (videoEl.current && !scanner.current) {
      scanner.current = new QrScanner(
        videoEl.current as any,
        (result) => {
          console.log(result);
          onScan?.(result.data);
        },
        {
          onDecodeError: (error) => {
            console.error(error);
          },
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );

      scanner.current.start();
    }
  }, [onScan]);

  useEffect(() => {
    if (scanner.current) {
      if (isVisible) {
        scanner.current.start();
      } else {
        scanner.current.stop();
      }
    }
  }, [isVisible]);

  return <video height={500} ref={videoEl}></video>;
}
