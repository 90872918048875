import './styles.css';

import { useState } from 'react';
import { v4 } from 'uuid';

import { authenticate } from '../../api/authentication';
import { createExecution } from '../../api/create-execution';
import { getExecution } from '../../api/get-execution';
import { lookup as lookupCall } from '../../api/lookup';
import { startPaymentSession } from '../../api/start-payment-session';
import { Card } from '../../components/card';
import { useConfiguration } from '../../components/configuration';
import { InfoCard } from '../../components/info-card';
import { RequestCard } from '../../components/request-card';
import {
  CREATE_EXECUTION_PAYLOAD,
  CREATE_EXECUTION_PAYLOAD_WITH_START_PAYMENT_SESSION,
  withConfiguration,
} from '../../example-payloads/create-execution';
import { START_PAYMENT_SESSION_ADYEN_DROPIN } from '../../example-payloads/start-payment-session';

export const Api = () => {
  const [config] = useConfiguration();
  const [context, setContext] = useState({
    bearer: null as any,
    execution: null as any,
    lookup: null as any,
    paymentSession: null as any,
  });

  const [apiRes, setRequest] = useState([] as Array<any>);

  const createApiCallAction = (apiCaller: any) => {
    return async () => {
      setRequest([{ loading: true }, { loading: true }]);

      const [req, res] = await apiCaller();
      setRequest([req, res]);
      if (res?.errors && res?.errors[0]?.status === '401') {
        setContext({
          ...context,
          bearer: null,
          execution: null,
          lookup: null,
          paymentSession: null,
        });
      }
    };
  };

  const { payrailsApiKey, payrailsClientId, baseUrl } = config;

  const { execution, bearer } = context;

  const createExecutionFactory = (payload: any) => {
    return async () => {
      const { req, res } = await createExecution(
        bearer,
        withConfiguration(
          { ...payload, merchantReference: `order-${v4()}` },
          config
        ),

        baseUrl
      );

      const initialResults = res.initialResults || [];
      const paymentSessionResponse = initialResults.find(
        (result: any) => result.body.name === 'startPaymentSession'
      );

      setContext({
        ...context,
        execution: res,
        lookup: null,
        paymentSession: paymentSessionResponse?.body || null,
      });

      return [req, res];
    };
  };

  const doStartPaymentSession = async () => {
    const req = await startPaymentSession(bearer, execution.id, baseUrl);
    const res = await req.json();

    setContext({
      ...context,
      paymentSession: res,
    });

    return [
      {
        url: req.url,
        method: 'POST',
        headers: { authorization: bearer, 'x-idempotency-key': v4() },
        body: START_PAYMENT_SESSION_ADYEN_DROPIN,
      },
      res,
    ];
  };

  const doLookup = async () => {
    const req = await lookupCall(bearer, execution.id, baseUrl);
    const res = await req.json();

    setContext({
      ...context,
      lookup: res,
    });

    return [
      {
        url: req.url,
        method: 'POST',
        headers: { authorization: bearer, 'x-idempotency-key': v4() },
      },
      res,
    ];
  };

  const doAuthentication = async () => {
    const { req, res } = await authenticate(
      payrailsClientId,
      payrailsApiKey,
      baseUrl
    );

    setContext({
      ...context,
      bearer: `Bearer ${res.access_token}`,
    });

    return [req, res];
  };

  const getLastExecution = async () => {
    const req = await getExecution(bearer, execution.id, baseUrl);
    const res = await req.json();

    return [{ url: req.url, method: 'GET' }, res];
  };

  return (
    <>
      <Card className="actions-wrapper" header="Actions">
        <div className="action-buttons-wrapper">
          <button
            onClick={createApiCallAction(doAuthentication)}
            disabled={!payrailsApiKey || !payrailsClientId}
          >
            Authenticate
          </button>
          <button
            onClick={createApiCallAction(
              createExecutionFactory(CREATE_EXECUTION_PAYLOAD)
            )}
            disabled={!bearer}
          >
            Create new execution
          </button>
          <button
            onClick={createApiCallAction(
              createExecutionFactory(
                CREATE_EXECUTION_PAYLOAD_WITH_START_PAYMENT_SESSION
              )
            )}
            disabled={!bearer}
          >
            New execution with payment session
          </button>
          <button
            onClick={createApiCallAction(getLastExecution)}
            disabled={!execution?.id}
          >
            Get execution
          </button>
          <button
            onClick={createApiCallAction(doLookup)}
            disabled={!execution?.id}
          >
            Lookup
          </button>
          <button
            onClick={createApiCallAction(doStartPaymentSession)}
            disabled={!execution?.id}
          >
            Start payment session
          </button>
          <span id="gpay"></span>
        </div>
      </Card>

      <InfoCard
        executionId={execution?.id}
        merchantReference={execution?.merchantReference}
      />

      {apiRes[0] && (
        <RequestCard
          header="Payloads"
          requestPayload={apiRes[0]}
          responsePayload={apiRes[1]}
        />
      )}
    </>
  );
};
