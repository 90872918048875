import './styles.css';

interface Props {
  requestPayload?: { [key: string]: any };
  responsePayload?: { [key: string]: any };
}

export const RequestLog = ({ requestPayload, responsePayload }: Props) => {
  return (
    <div className="payloads-wrapper">
      {requestPayload && (
        <div className="payload">
          <h3>Request</h3>
          <pre>{JSON.stringify(requestPayload, null, 2)}</pre>
        </div>
      )}

      {responsePayload && (
        <div className="payload">
          <h3>Response</h3>
          <pre>{JSON.stringify(responsePayload, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};
