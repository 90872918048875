import { buildUrl } from './http-utils';

export const getExecution = (
  bearer: string,
  executionId: string,
  baseUrl: string,
  statusesToWait?: string[]
) => {
  const url = buildUrl(
    baseUrl,
    `merchant/workflows/payment-acceptance/executions/${executionId}${
      statusesToWait
        ? `?waitWhile[status]=[${statusesToWait
            ?.map((i) => `"${i}"`)
            .join(',')}]`
        : ''
    }`
  );

  return fetch(url, {
    method: 'GET',
    headers: { authorization: bearer },
  });
};
