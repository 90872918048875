import { useState } from 'react';
import { v4 } from 'uuid';

import {
  authenticate,
  AuthenticationRequestResult,
} from '../../api/authentication';
import {
  createExecution,
  CreateExecutionRequestResult,
} from '../../api/create-execution';
import { AdyenDropInThreeStep } from '../../components/adyen-drop-in-three-step';
import { AuthenticationCard } from '../../components/authentication-card';
import { Card } from '../../components/card';
import { useConfiguration } from '../../components/configuration';
import { InfoCard } from '../../components/info-card';
import { RequestCard } from '../../components/request-card';
import {
  CREATE_EXECUTION_PAYLOAD,
  CREATE_EXECUTION_PAYLOAD_WITH_LOOKUP,
  withConfiguration,
} from '../../example-payloads/create-execution';

export const ThreeStepDropinFlow = () => {
  const [config] = useConfiguration();
  const [authentication, setAuthentication] =
    useState<AuthenticationRequestResult>();
  const [execution, setExecution] = useState<CreateExecutionRequestResult>();

  const {
    payrailsApiKey,
    payrailsClientId,
    baseUrl,
    adyenClientKey,
    authorizationAmount,
  } = config;

  const initDropin = async ({ execute3Ds = false } = {}) => {
    const authResult = await authenticate(
      payrailsClientId,
      payrailsApiKey,
      baseUrl
    );

    if (!authResult.ok) {
      console.error('Authentication not successful');
      return;
    }

    const bearer = `Bearer ${authResult.res.access_token}`;

    setAuthentication(authResult);

    const createExecPayload: { [key: string]: any } = withConfiguration(
      {
        ...CREATE_EXECUTION_PAYLOAD,
        initialActions: [
          {
            action: 'lookup',
            method: 'POST',
            body: {
              country: {
                code: 'DE',
              },
              amount: {
                value: authorizationAmount,
                currency: 'EUR',
              },
            },
          },
        ],
        merchantReference: `order-${v4()}`,
      },
      config
    );

    if (execute3Ds) {
      createExecPayload.meta = {
        ...CREATE_EXECUTION_PAYLOAD_WITH_LOOKUP.meta,
        clientContext: {
          ...createExecPayload.meta.clientContext,
          userAgent: 'other',
          acceptHeader: 'other',
        },
      };
    }

    const payload = withConfiguration(createExecPayload, config);
    const createExec = await createExecution(bearer, payload, baseUrl);

    if (!createExec.ok) {
      console.error('Workflow execution not created');
      return;
    }

    setExecution(createExec);
  };

  const lookup = execution?.res.initialResults[0].body;

  return (
    <>
      <Card header="Actions">
        <button
          onClick={() => initDropin()}
          disabled={
            !payrailsClientId || !payrailsApiKey || !baseUrl || !adyenClientKey
          }
        >
          Pay with 3 step Adyen dropin
        </button>
        <button
          onClick={() => initDropin({ execute3Ds: true })}
          disabled={
            !payrailsClientId || !payrailsApiKey || !baseUrl || !adyenClientKey
          }
        >
          Pay with 3 step Adyen dropin 3DS
        </button>
      </Card>
      <InfoCard
        merchantReference={execution?.res.merchantReference}
        executionId={execution?.res.id}
      />

      <AuthenticationCard
        req={authentication?.req}
        res={authentication?.res}
        isCollapsed={true}
      />

      <RequestCard
        requestPayload={execution?.req}
        responsePayload={execution?.res}
        header="Create execution response"
        isCollapsed={true}
      />

      {lookup && authentication && execution?.ok && adyenClientKey && (
        <AdyenDropInThreeStep
          bearer={`Bearer ${authentication.res.access_token}`}
          executionId={execution.res.id}
          adyenClientKey={adyenClientKey}
          baseUrl={baseUrl}
          paymentMethodsResponse={
            lookup.data.paymentCompositionOptions.find(
              ({ integrationType }: { integrationType: string }) =>
                integrationType === 'adyenDropInThreeSteps'
            ).providerData
          }
        />
      )}
    </>
  );
};
