import { OsType } from '../../example-payloads/create-execution';

import { ConfigurationObject } from './types';

interface TextConfigFieldMap {
  label: string;
  fieldName: keyof ConfigurationObject;
  defaultValue: string;
  password?: boolean;
  hidden?: boolean;
}

export const COMMON_TEXT_FIELDS: Array<TextConfigFieldMap> = [
  {
    label: 'API Base URL',
    fieldName: 'baseUrl',
    defaultValue: 'https://demo-api.staging.payrails.io/',
  },
  {
    label: 'Payrails API key',
    fieldName: 'payrailsApiKey',
    defaultValue: '',
    password: true,
  },
  {
    label: 'Payrails Client ID',
    fieldName: 'payrailsClientId',
    defaultValue: '',
    password: true,
  },
  {
    label: 'Holder reference',
    fieldName: 'holderReference',
    defaultValue: 'customer-1',
  },
  {
    label: 'Amount',
    fieldName: 'authorizationAmount',
    defaultValue: '100',
  },
  {
    label: 'Workflow config version',
    fieldName: 'workflowConfigVersion',
    defaultValue: '',
  },
];

export const ADYEN_TEXT_FIELDS: Array<TextConfigFieldMap> = [
  {
    label: 'Adyen Merchant Account',
    fieldName: 'adyenMerchantId',
    defaultValue: 'PayrailsGmbHECOM',
    hidden: true,
  },
  {
    label: 'Adyen Client Key',
    fieldName: 'adyenClientKey',
    defaultValue: '',
    hidden: true,
  },
];

export const STRIPE_TEXT_FIELDS: Array<TextConfigFieldMap> = [];

export function getSessionStorageValue(key: string, defaultValue: string) {
  return sessionStorage.getItem(key)
    ? JSON.parse(sessionStorage.getItem(key) ?? '')
    : defaultValue;
}

const INITIAL_TEXT_VALUES = [
  ...COMMON_TEXT_FIELDS,
  ...ADYEN_TEXT_FIELDS,
].reduce((prev, { fieldName, defaultValue }) => {
  return {
    ...prev,
    [fieldName]: getSessionStorageValue(fieldName, defaultValue),
  };
}, {} as { [key in keyof ConfigurationObject]: string });

export const CONFIG_INITIAL_VALUES = {
  ...INITIAL_TEXT_VALUES,
  psp: getSessionStorageValue('psp', 'stripe'),
  osType: getSessionStorageValue('osType', OsType.ANDROID),
  useCVCForStoredCards:
    sessionStorage.getItem('useCVCForStoredCards') === 'false' ? false : true,
  vaultType: getSessionStorageValue('vaultType', 'Skyflow'),
};
