import { createContext, useContext } from 'react';

import { CONFIG_INITIAL_VALUES } from './fields';
import { ConfigurationObject } from './types';

export const ConfigurationContext = createContext<
  [
    ConfigurationObject,
    <TKey extends keyof ConfigurationObject>(key: TKey, value: any) => void
  ]
>([CONFIG_INITIAL_VALUES, () => undefined]);

export const useConfiguration = () => useContext(ConfigurationContext);
