import { Payrails } from '@payrails/web-sdk';

import { authenticate } from '../api/authentication';
import { initClient } from '../api/init-client';
import { ConfigurationObject } from '../components/configuration';
import {
  CREATE_EXECUTION_PAYLOAD_WITH_LOOKUP,
  withConfiguration,
} from '../example-payloads/create-execution';

export const initPayrailsSdk = async (config: ConfigurationObject) => {
  const { payrailsClientId, payrailsApiKey, baseUrl } = config;

  const authentication = await authenticate(
    payrailsClientId,
    payrailsApiKey,
    baseUrl
  );

  const wfPayload = withConfiguration(
    CREATE_EXECUTION_PAYLOAD_WITH_LOOKUP,
    config
  );

  const authHeader = `Bearer ${authentication.res.access_token}`;
  const initResult = await initClient(
    wfPayload.holderReference,
    wfPayload.merchantReference,
    wfPayload.amount,
    wfPayload.meta,
    authHeader,
    baseUrl
  );

  const payrails = Payrails.init(initResult.res, {
    events: {
      onClientInitialized: (workflow: any) => {
        console.log('client initialized', workflow);
      },
    },
    telemetry: {
      enabled: true,
    },
  });

  return {
    payrails,
    initResult,
  };
};
