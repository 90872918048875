import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';

import { ConfigurationObject, useConfiguration } from '../configuration';
import { QrCodeScanner } from '../qr-code-scanner';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
export function QrCodeScannerModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [_, setConfig] = useConfiguration();

  const onScan = useCallback(
    (data: string) => {
      try {
        const config: ConfigurationObject = JSON.parse(data);
        Object.entries(config)
          .filter(([key, value]) => Boolean(key && value))
          .forEach(([key, value]) => {
            setConfig(key as keyof ConfigurationObject, value);
          });
      } catch (e) {
        console.error(e);
      }
      setIsModalOpen(false);
    },
    [setConfig]
  );

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        contentLabel="QR Code Scanner"
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        style={modalStyles}
        ariaHideApp={false}
      >
        <div>QR Code Scanner</div>
        <QrCodeScanner isVisible={isModalOpen} onScan={onScan} />
      </Modal>
      <button onClick={() => setIsModalOpen(true)}> Scan QR Code </button>
    </>
  );
}
