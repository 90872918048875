import { v4 } from 'uuid';

import { ConfigurationObject } from '../components/configuration';

import { LOOKUP_WITHOUT_PLATFORM } from './lookup';
import { START_PAYMENT_SESSION_ADYEN_DROPIN } from './start-payment-session';

export enum OsType {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
}

export enum VaultType {
  PAYRAILS = 'Payrails',
  SKYFLOW = 'Skyflow',
  Test = 'Test',
}

export function withConfiguration(payload: any, config: ConfigurationObject) {
  const {
    osType,
    holderReference,
    psp,
    authorizationAmount,
    workflowConfigVersion,
    vaultType,
  } = config;

  return withWorkflowConfigVersion(
    withAmount(
      withVaultConfig(
        withPsp(
          withOsType(withHolderReference(payload, holderReference), osType),
          psp
        ),
        vaultType
      ),
      authorizationAmount
    ),
    workflowConfigVersion
  );
}

export const CREATE_EXECUTION_PAYLOAD = {
  merchantReference: `order-${v4()}`,
  holderReference: 'customer-1',
  amount: {
    currency: 'EUR',
    value: '100',
  },
  meta: {
    allowedPaymentMethods: ['card', 'klarna', 'klarna_account', 'applepay'],
    enableCard: 'true',
    customer: {
      reference: 'customer-1',
    },
    clientContext: {
      osType: 'ios',
      ipAddress: '18.196.176.219',
      acceptHeader: 'html',
      userAgent: 'Firefox',
      IPAddress: '93.146.254.172',
      javaEnabled: true,
      javaScriptEnabled: true,
      language: 'EN',
      colorDepth: 48,
      screenHeight: 400,
      screenWidth: 600,
      timeZoneOffset: 0,
      allowNative3DS: false,
    },
    order: {
      reference: `order-${v4()}`,
      description: 'test order',
      lines: [
        {
          id: v4(),
          description: 'test',
          quantity: 1,
          unitPrice: {
            value: '44.44',
            currency: 'EGP',
          },
        },
      ],
    },
  },
  initialActions: [],
};

export const CREATE_EXECUTION_PAYLOAD_WITH_LOOKUP = {
  ...CREATE_EXECUTION_PAYLOAD,
  initialActions: [
    { action: 'lookup', method: 'POST', body: LOOKUP_WITHOUT_PLATFORM },
  ],
};

export const CREATE_EXECUTION_PAYLOAD_WITH_START_PAYMENT_SESSION = {
  ...CREATE_EXECUTION_PAYLOAD,
  initialActions: [
    {
      action: 'startPaymentSession',
      method: 'POST',
      body: START_PAYMENT_SESSION_ADYEN_DROPIN,
    },
  ],
};

export const CREATE_EXECUTION_PAYLOAD_WITH_START_PAYMENT_SESSION_AND_LOOKUP = {
  ...CREATE_EXECUTION_PAYLOAD,
  initialActions: [
    {
      action: 'startPaymentSession',
      method: 'POST',
      body: START_PAYMENT_SESSION_ADYEN_DROPIN,
    },
    { action: 'lookup', method: 'POST', body: LOOKUP_WITHOUT_PLATFORM },
  ],
};

function withOsType(payload: any, osType: OsType) {
  const clone = JSON.parse(JSON.stringify(payload));
  clone['meta']['clientContext']['osType'] = osType;

  return clone;
}
function withVaultConfig(payload: any, vaultType: string) {
  const clone = JSON.parse(JSON.stringify(payload));

  clone.meta.vaultType = vaultType;

  return clone;
}

function withHolderReference(payload: any, holderReference: string) {
  const clone = JSON.parse(JSON.stringify(payload));

  clone.holderReference = holderReference;
  clone.meta.customer.reference = holderReference;

  return clone;
}

function withPsp(payload: any, psp: string) {
  const clone = JSON.parse(JSON.stringify(payload));
  if (!psp || psp === 'adyen') {
    return clone;
  }

  clone['meta']['paymentProviderOverride'] = { code: psp };

  return clone;
}

function withAmount(payload: any, amountValue: string) {
  const clone = JSON.parse(JSON.stringify(payload));
  clone.amount.value = amountValue;
  if (clone.meta.order?.lines?.length > 0) {
    clone.meta.order.lines[0].unitPrice.value = amountValue;
  }

  return clone;
}

function withWorkflowConfigVersion(
  payload: any,
  workflowConfigVersion: string
) {
  const clone = JSON.parse(JSON.stringify(payload));
  clone.meta.workflowConfigVersion = parseInt(workflowConfigVersion, 10);

  return clone;
}
