import { v4 } from 'uuid';

import { buildUrl } from './http-utils';
import { ApiEnvelope } from './types';

interface Req {
  url: string;
  method: string;
  headers: {
    authorization: string;
  };
}

interface Res {
  version: '1.0.0';
  data: string;
}

export type InitClientRequestResult = ApiEnvelope<Req, Res>;

export const initClient = async (
  holderReference: string,
  merchantReference: string,
  amount: any,
  meta: any,
  authHeader: string,
  baseUrl: string
): Promise<InitClientRequestResult> => {
  const url = buildUrl(baseUrl, `merchant/client/init`);
  const reqInit = {
    method: 'POST',
    headers: {
      authorization: authHeader,
      'x-idempotency-key': v4(),
    },
    body: JSON.stringify({
      holderReference,
      type: 'dropIn',
      workflowCode: 'payment-acceptance',
      merchantReference,
      meta,
      amount,
    }),
  };

  try {
    const req = await fetch(url, reqInit);
    const res = await req.json();

    return {
      ok: req.ok,
      req: {
        url,
        ...reqInit,
      },
      res,
    };
  } catch (e) {
    console.log(e);
    throw new Error('Vault configuration request failed');
  }
};
