import { CardFormOptions, CardPaymentButtonOptions } from '@payrails/web-sdk';

export const CARD_FORM_CONFIG: CardFormOptions = {
  showCardHolderName: true,
  translations: {
    labels: {
      CARD_NUMBER: 'Kortelės numeris',
      CARDHOLDER_NAME: 'Pilnas vardas kortelėje',
      CVV: 'CVV kodas',
      EXPIRATION_MONTH: 'Galioja iki',
      EXPIRATION_YEAR: ' ',
    },
    placeholders: {
      CARD_NUMBER: 'pvz. 1234 1234 1234 1234',
      CARDHOLDER_NAME: 'Įvesk savo vardą ir pavardę',
      CVV: 'pvz. 123',
    },
  },
  alwaysStoreInstrument: true,
  styles: {
    base: {
      fontFamily: "'Open Sans', sans-serif",
      borderRadius: 'none',
    },
    inputFields: {
      all: {
        base: {
          lineHeight: '22px',
          fontFamily: "'Open Sans', sans-serif",
          fontSize: '16px',
          borderTopLeftRadius: 'none',
          borderTopRightRadius: 'none',
          borderBottomLeftRadius: 'none',
          borderBottomRightRadius: 'none',
          border: 'none',
          borderBottom: '1px solid rgba(23, 23, 23, 0.08)',
          borderRight: 'none',
          borderLeft: 'none',
          paddingBottom: '10px',
          paddingLeft: '2px',
        },
        focus: {
          borderBottom: '1px solid rgba(0,119,130,1)',
        },
      },
      CARD_NUMBER: {
        cardIcon: {
          width: '16px',
          height: '16px',
          bottom: 'calc(50% - 8px)',
        },
      },
      EXPIRATION_YEAR: {
        base: {
          margin: '0 4px 0 4px',
          width: 'calc(100% - 8px)',
        },
      },
    },
    labels: {
      all: {
        fontFamily: "'Open Sans', sans-serif",
        fontSize: '14px',
        lineHeight: '18px',
        display: 'inline-block',
        marginTop: '32px',
        color: 'rgba(117, 117, 117, 1)',
      },
      CARDHOLDER_NAME: {
        marginTop: '-32px',
      },
      EXPIRATION_YEAR: {
        height: '14px',
      },
    },
  },
};

export const PAYMENT_BUTTON_CONFIG: CardPaymentButtonOptions = {
  translations: {
    label: 'Naudoti šią kortelę',
  },
  styles: {
    base: {
      backgroundColor: 'rgba(0,119,130,1)',
      width: '100%',
      border: 'none',
      fontSize: '16px',
      fontWeight: '500',
    },
    disabled: {
      backgroundColor: '#D3D3D3',
      color: '#fff',
    },
  },
};
