import './styles.css';

import { useState } from 'react';
import { Payrails } from '@payrails/web-sdk';

import { Card } from '../../components/card';
import { useConfiguration } from '../../components/configuration';
import { initPayrailsSdk } from '../../helpers/init-payrails-sdk';

import { CARD_FORM_CONFIG, PAYMENT_BUTTON_CONFIG } from './config';

export const CustomCardForm = () => {
  const [config] = useConfiguration();
  const [paymentResult, setPaymentResult] = useState<string | null>(null);

  let form: ReturnType<Payrails['cardForm']> | undefined;
  let paymentButton: ReturnType<Payrails['paymentButton']> | undefined;

  const initForm = async () => {
    ['card-form', 'payment-button'].forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        element.innerHTML = '';
      }
    });

    setPaymentResult(null);
    const { payrails } = await initPayrailsSdk(config);
    setPaymentResult('pending');

    form = payrails.cardForm(CARD_FORM_CONFIG);
    form.mount('#card-form');

    paymentButton = payrails.paymentButton({
      ...PAYMENT_BUTTON_CONFIG,
      events: {
        onAuthorizeSuccess: () => {
          setPaymentResult('success');
          form?.unmount();
          paymentButton?.unmount();
        },
        onAuthorizeFailed: () => {
          setPaymentResult('failed');
          form?.unmount();
          paymentButton?.unmount();
        },
      },
    });
    paymentButton.mount('#payment-button');
  };

  return (
    <>
      <Card header={'Actions'}>
        <button onClick={initForm}>Init custom card form</button>
      </Card>

      <div
        className="card-form-backdrop"
        style={
          paymentResult === 'pending'
            ? { display: 'flex' }
            : { display: 'none' }
        }
      >
        <div className="card-form-popup">
          <div className="card-form-header">Kortelės duomenys</div>
          <div className="card-form-wrapper">
            <div id="card-form" />
          </div>
          <div id="payment-button" />
        </div>
      </div>

      {['failed', 'success'].includes(paymentResult ?? '') && (
        <Card header={'Payment result'}>Payment result: {paymentResult}</Card>
      )}
    </>
  );
};
