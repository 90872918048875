import './styles.css';

import { Card } from '../card';

interface Props {
  executionId?: string;
  merchantReference?: string;
}

const copyToClipboard = async (e: React.MouseEvent<HTMLButtonElement>) => {
  const id = e.currentTarget.dataset.id || '';
  await navigator.clipboard.writeText(id);
};

export const InfoCard = ({
  executionId = '',
  merchantReference = '',
}: Props) => {
  if (!executionId && !merchantReference) return <></>;

  return (
    <Card className="info-card-wrapper" header="Info">
      <div className="info-blocks-wrapper">
        {executionId && (
          <div>
            <label>Execution ID:</label>
            <input
              id="executionId"
              type="text"
              readOnly={true}
              value={executionId}
            />
            <button onClick={copyToClipboard} data-id={executionId}>
              Copy
            </button>
          </div>
        )}
        {merchantReference && (
          <div>
            <label>Merchant Reference:</label>
            <input
              id="merchantReference"
              type="text"
              readOnly={true}
              value={merchantReference}
            />
            <button onClick={copyToClipboard} data-id={merchantReference}>
              Copy
            </button>
          </div>
        )}
      </div>
    </Card>
  );
};
