import './styles.css';

import { FormEvent, useRef, useState } from 'react';
import { Card as CardObj, PayrailsCSE } from '@payrails/web-cse';

import { authenticate } from '../../api/authentication';
import { initTokenization } from '../../api/init-tokenization';
import { Card } from '../../components/card';
import { useConfiguration } from '../../components/configuration';
import { RequestCard } from '../../components/request-card';

export const CSE = () => {
  const [config] = useConfiguration();
  const {
    baseUrl,
    payrailsClientId,
    payrailsApiKey,
    holderReference,
    vaultType,
  } = config;
  const [resultCard, setResultCard] = useState<any>();
  const [operationType, setOperationType] = useState<any>();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    setLoading(true);
    setResultCard(undefined);
    setOperationType('Tokenized card');
    e.preventDefault();

    try {
      const authentication = await authenticate(
        payrailsClientId,
        payrailsApiKey,
        baseUrl
      );
      const authHeader = `Bearer ${authentication.res.access_token}`;

      const meta: Map<string, string> = new Map<string, string>();
      meta.set('vaultType', vaultType);
      const clientInitResult = await initTokenization(
        holderReference,
        authHeader,
        baseUrl,
        meta
      );
      const cse = PayrailsCSE.init(clientInitResult.res);
      const target: any = e.target;
      const card: CardObj = {
        cardNumber: target[0].value,
        holderName: target[1].value,
        expiryMonth: target[2].value,
        expiryYear: target[3].value,
        securityCode: target[4].value,
      };

      const futureUsageEl = document.querySelector(
        '#cse-future-usage'
      ) as HTMLSelectElement;

      const storeInstrumentEl = document.querySelector(
        '#cse-store-instrument'
      ) as HTMLInputElement;

      const tokenizationReq = await cse.tokenize(card, {
        futureUsage: futureUsageEl.value as any,
        storeInstrument: storeInstrumentEl.checked,
      });
      const res = await tokenizationReq.json();

      setResultCard(res);
      setLoading(false);
    } catch (_) {
      setLoading(false);
    }
  };

  const encryptCardData = async () => {
    setLoading(true);
    setResultCard(undefined);
    setOperationType('Encrypted card');

    try {
      const authentication = await authenticate(
        payrailsClientId,
        payrailsApiKey,
        baseUrl
      );

      const authHeader = `Bearer ${authentication.res.access_token}`;

      const meta: Map<string, string> = new Map<string, string>();
      meta.set('vaultType', vaultType);
      const clientInitResult = await initTokenization(
        holderReference,
        authHeader,
        baseUrl,
        meta
      );
      const cse = PayrailsCSE.init(clientInitResult.res);
      const card: CardObj = {
        cardNumber: cardNumberInput.current?.value,
        holderName: cardholderNameInput.current?.value,
        expiryMonth: expirationMonthInput.current?.value,
        expiryYear: expirationYearInput.current?.value,
        securityCode: securityCodeInput.current?.value,
      };

      const req = {
        card,
        data: {},
      };
      console.log(req);
      const encryptCardDataResult = await cse.encryptCardData(req);
      setResultCard(encryptCardDataResult);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const cardNumberInput = useRef<HTMLInputElement>(null);
  const cardholderNameInput = useRef<HTMLInputElement>(null);
  const expirationMonthInput = useRef<HTMLInputElement>(null);
  const expirationYearInput = useRef<HTMLInputElement>(null);
  const securityCodeInput = useRef<HTMLInputElement>(null);

  return (
    <>
      <Card header={'Tokenization config'}>
        <label>
          Future usage:
          <br />
          <select id="cse-future-usage">
            <option value="CardOnFile">CardOnFile</option>
            <option value="Subscription">Subscription</option>
            <option value="UnscheduledCardOnFile">UnscheduledCardOnFile</option>
          </select>
        </label>

        <label>
          Store instrument:
          <input
            type="checkbox"
            name="storeInstrument"
            className="store-instrument-checkbox"
            id="cse-store-instrument"
            defaultChecked={true}
          />
        </label>
      </Card>
      <Card header={'Test encryption'}>
        <form onSubmit={onSubmit}>
          <label>
            Card number
            <input
              type="text"
              name="cardNumber"
              defaultValue="4111111111111111"
              ref={cardNumberInput}
            />
          </label>
          <label>
            Cardholder name
            <input
              type="text"
              name="cardholderName"
              defaultValue="Test Cardholder"
              ref={cardholderNameInput}
            />
          </label>
          <label>
            Expiration month
            <input
              type="text"
              name="expirationMonth"
              defaultValue="03"
              ref={expirationMonthInput}
            />
          </label>
          <label>
            Expiration year
            <input
              type="text"
              name="expirationYear"
              defaultValue="30"
              ref={expirationYearInput}
            />
          </label>
          <label>
            Security code
            <input
              type="text"
              name="securityCode"
              defaultValue="737"
              ref={securityCodeInput}
            />
          </label>
          <button
            type={'submit'}
            disabled={loading}
            style={{ margin: '16px 0' }}
          >
            Tokenize card
          </button>
          <button
            onClick={encryptCardData}
            disabled={loading}
            style={{ margin: '16px 0' }}
          >
            Encrypt card
          </button>
        </form>
      </Card>
      {resultCard && (
        <RequestCard header={operationType} responsePayload={resultCard} />
      )}
    </>
  );
};
