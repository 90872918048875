import './styles.css';

import { FormEvent } from 'react';
import { useLocation } from 'react-router';

import { OsType, VaultType } from '../../example-payloads/create-execution';
import { Card } from '../card';
import { ConfigQRCodeModal } from '../config-qr-code-modal';
import { QrCodeScannerModal } from '../qr-code-scanner-modal';

import { ADYEN_TEXT_FIELDS, COMMON_TEXT_FIELDS } from './fields';
import { useConfiguration } from '.';

export const Configuration = () => {
  const [config, setConfig] = useConfiguration();
  const createConfigChangeHandler = (configField: keyof typeof config) => {
    return (e: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      setConfig(configField, e.currentTarget.value);
    };
  };

  const isTestMode = sessionStorage.getItem('testMode') === 'true';

  const { osType, psp, useCVCForStoredCards, vaultType } = config;

  const location = useLocation();

  return (
    <Card
      className="configuration-wrapper"
      header="Configuration"
      isCollapsed={sessionStorage.getItem('isConfigCollapsed') === 'true'}
      onCollapsed={(collapsed) => {
        sessionStorage.setItem('isConfigCollapsed', JSON.stringify(collapsed));
      }}
    >
      {COMMON_TEXT_FIELDS.map(({ fieldName, label, password }) => {
        return (
          <div key={fieldName}>
            <label>{`${label}:`}</label>
            <input
              id={fieldName}
              type={password ? 'password' : 'text'}
              onChange={createConfigChangeHandler(fieldName)}
              value={config[fieldName] as string}
            />
          </div>
        );
      })}
      <div>
        <label>Vault:</label>
        <select
          id="dropInVaultType"
          name="dropInVaultType"
          value={vaultType}
          onChange={createConfigChangeHandler('vaultType')}
        >
          <option value={VaultType.SKYFLOW}>Skyflow</option>
          <option value={VaultType.PAYRAILS}>Payrails</option>
        </select>

        <label>OS Type:</label>
        <select
          id="dropInOsType"
          name="dropInOsType"
          value={osType}
          onChange={createConfigChangeHandler('osType')}
        >
          <option value={OsType.IOS}>iOS</option>
          <option value={OsType.ANDROID}>Android</option>
          <option value={OsType.WEB}>Web</option>
        </select>
      </div>
      {location.pathname === '/three-step-dropin' && (
        <div className="checkbox-row">
          <label>Use CVC on stored cards:</label>
          <input
            type="checkbox"
            checked={useCVCForStoredCards}
            onChange={(e) => {
              sessionStorage.setItem(
                'useCVCForStoredCards',
                JSON.stringify(e.currentTarget.checked)
              );
              setConfig('useCVCForStoredCards', e.currentTarget.checked);
            }}
          />
        </div>
      )}
      {isTestMode && (
        <>
          <div>
            <label>Provider</label>
            <select
              id="psp"
              name="psp"
              value={psp}
              onChange={createConfigChangeHandler('psp')}
            >
              <option value={'stripe'}>Stripe</option>
              <option value={'adyen'}>Adyen</option>
              <option value={'paypal'}>Paypal</option>
              <option value={'checkout'}>Checkout</option>
              <option value={'nuvei'}>Nuvei</option>
              <option value={'testPsp'}>Test PSP</option>
            </select>
          </div>
          {psp === 'adyen' && (
            <>
              {ADYEN_TEXT_FIELDS.map(({ fieldName, label }) => {
                return (
                  <div key={fieldName}>
                    <label>{`${label}:`}</label>
                    <input
                      id={fieldName}
                      type="text"
                      onChange={createConfigChangeHandler(fieldName)}
                      defaultValue={config[fieldName] as string}
                    />
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
      <ConfigQRCodeModal />
      <QrCodeScannerModal />
    </Card>
  );
};
