import './styles.css';
import '@payrails/web-sdk/payrails-styles.css';

import { useState } from 'react';
import { ElementType } from '@payrails/web-sdk';

import { initClient } from '../../api/init-client';
import { Card } from '../../components/card';
import { useConfiguration } from '../../components/configuration';
import { InfoCard } from '../../components/info-card';
import { RequestCard } from '../../components/request-card';
import { initPayrailsSdk } from '../../helpers/init-payrails-sdk';

export const Tokenization = () => {
  const [config] = useConfiguration();
  const [dropinConfig, setDropinConfig] = useState({
    showCardHolderName: false,
    showExistingCards: true,
    showStoreInstrumentCheckbox: true,
    customTranslations: false,
  });
  const [initConfig, setInitConfig] =
    useState<Awaited<ReturnType<typeof initClient>>>();
  const [displayedMode, setDisplayedMode] = useState<
    'dropin' | 'components' | 'none' | 'secure-fields'
  >('none');
  const [clientConfig, setClientConfig] = useState<any>();

  const { baseUrl, payrailsClientId, payrailsApiKey } = config;

  const initPayrails = async () => {
    const { initResult, payrails } = await initPayrailsSdk(config);

    setInitConfig(initResult);
    setClientConfig(JSON.parse(atob(initResult.res.data)));

    return payrails;
  };

  const paymentComponentsConfig = {
    events: {
      onAuthorizeSuccess() {
        console.log('yay!');
      },
      onAuthorizeFailed(e: any) {
        console.log('nay :(', e.code);
      },
      onPaymentButtonClicked(e: any) {
        console.log('payment button clicked', e);

        return Promise.resolve(true);
      },
      onPaymentSessionExpired() {
        console.log('payment session expired');
      },
      onSaveInstrumentCheckboxChanged() {
        console.log('save instrument checkbox value changed');
      },
      onThreeDSecureChallenge() {
        throw new Error('3ds challenge');
      },
    },
    returnInfo: {
      success: '/info',
      error: '/error',
    },
  };

  const cardFormTranslations = {
    placeholders: {
      CARDHOLDER_NAME: 'Customized card holder',
      CARD_NUMBER: 'Custom card number',
      CVV: 'CODE',
      EXPIRATION_MONTH: 'MO',
      EXPIRATION_YEAR: 'YEAR',
    },
    labels: {
      saveCreditCard: 'Save my card - customized',
    },
  };

  const paymentButtonTranslations = {
    label: 'Save card',
  };

  const paymentButtonStyles: {
    base: Partial<CSSStyleDeclaration>;
    disabled?: Partial<CSSStyleDeclaration>;
    hover?: Partial<CSSStyleDeclaration>;
  } = {
    base: {
      width: '300px',
      border: '1px solid transparent',
      fontSize: '16px',
      borderRadius: '4px',
      backgroundColor: '#007580',
      borderColor: 'transparent',
      color: '#fff',
      padding: '8px 24px',
      height: '44px',
      lineHeight: '22px',
      fontWeight: '500',
    },
    disabled: {
      backgroundColor: '#d8dfe6',
      color: '#52667d',
    },
  };

  const cardFormStyles = {
    inputFields: {
      all: {
        base: {
          '&:hover': {
            border: '1px solid black',
          },
          textIndent: '0',
        },
        focus: {
          border: '1px solid black',
        },
      },
    },
  };

  const cleanupComponents = () => {
    document
      .querySelectorAll(
        '#dropin, #card-form, #payment-button, #card-list, #google-pay-button, #paypal-button, #secure-fields > div'
      )
      .forEach((el) => {
        el.replaceChildren();
      });
  };

  const initDropin = async () => {
    cleanupComponents();
    setDisplayedMode('dropin');

    const payrails = await initPayrails();

    const {
      showExistingCards,
      showCardHolderName,
      showStoreInstrumentCheckbox,
      customTranslations,
    } = dropinConfig;

    const fullDropinConfig: any = {
      paymentMethodsConfiguration: {
        cards: {
          showCardHolderName,
          showExistingCards,
          showStoreInstrumentCheckbox,
        },
        paypal: {
          showStoreInstrumentCheckbox,
        },
        googlePay: {
          showStoreInstrumentCheckbox,
        },
        applePay: {
          showStoreInstrumentCheckbox,
        },
      },
      ...paymentComponentsConfig,
    };

    if (customTranslations) {
      fullDropinConfig.translations = {
        cardForm: cardFormTranslations,
        cardPaymentButton: paymentButtonTranslations,
        paymentResult: {
          success: 'Success!',
          fail: 'Fail!',
        },
      };
    }
    const dropin = payrails.dropin(fullDropinConfig);

    dropin.mount('#dropin');
  };

  const initPayrailsComponents = async () => {
    cleanupComponents();
    setDisplayedMode('components');

    const payrails = await initPayrails();
    const { showStoreInstrumentCheckbox, showCardHolderName } = dropinConfig;
    // exposing payrails to window for testing purposes
    (window as any).payrails = payrails;

    const cardForm = payrails.cardForm({
      showCardHolderName,
      showStoreInstrumentCheckbox,
      translations: cardFormTranslations,
      styles: {
        ...cardFormStyles,
        storeCardCheckbox: {
          color: 'red',
        },
      },
      events: {
        ...paymentComponentsConfig.events,
        onChange(event) {
          console.log('card form change', event);
        },
        onFocus() {
          console.log('focus');
        },
        onReady() {
          console.log('card form ready');
        },
      },
    });
    cardForm.mount('#card-form');

    const paymentButton = payrails.paymentButton({
      translations: paymentButtonTranslations,
      events: {
        ...paymentComponentsConfig.events,
        onAuthorizeSuccess() {
          console.log('yay!');
          paymentButton.setLabel('Payment completed');
        },
        onAuthorizeFailed(e) {
          console.log('nay :(', e.code);
          paymentButton.setLabel('Payment failed');
        },
        onSaveInstrumentCheckboxChanged() {
          console.log('save instrument checkbox value changed');
        },
        onStateChanged(state: string) {
          console.log('state changed', state);
        },
      },
      styles: paymentButtonStyles,
    });
    paymentButton.mount('#payment-button');

    const cardList = payrails.cardList();
    cardList.mount('#card-list');

    const googlePay = payrails.googlePayButton({
      styles: {
        buttonColor: 'white',
        buttonType: 'subscribe',
        buttonSizeMode: 'static',
        storeInstrumentCheckbox: {
          color: 'blue',
          fontWeight: 'bold',
        },
      },
      translations: {
        labels: {
          storeInstrument: 'Google Pay save instrument label',
        },
      },
      events: paymentComponentsConfig.events,
      showStoreInstrumentCheckbox: dropinConfig.showStoreInstrumentCheckbox,
    });
    googlePay.mount('#google-pay-button');

    const paypalButton = payrails.paypalButton({
      events: paymentComponentsConfig.events,
      showStoreInstrumentCheckbox: dropinConfig.showStoreInstrumentCheckbox,
      styles: {
        storeInstrumentCheckbox: {
          color: 'red',
        },
      },
      translations: {
        labels: {
          storeInstrument: 'Paypal save instrument label',
        },
      },
    });
    paypalButton.mount('#paypal-button');
    console.log('paypal instruments', payrails.getSavedPaypalAccounts());

    const fraudNet = payrails.fraudNet('checkout-page');
    fraudNet.mount();
  };

  const initSecureFields = async () => {
    cleanupComponents();
    setDisplayedMode('secure-fields');

    const payrails = await initPayrails();

    const container = payrails.collectContainer({
      containerType: 'COLLECT',
    });

    const cardNumber = container.createCollectElement({
      type: ElementType.CARD_NUMBER,
      placeholder: 'Custom card number',
      label: 'Card Number',
      required: true,
      errorTextStyles: {
        base: {
          width: '100px',
          backgroundColor: 'green',
          color: 'gray',
        },
      },
    });

    cardNumber.mount?.('#card-number');

    const cardHolderName = container.createCollectElement({
      type: ElementType.CARDHOLDER_NAME,
      placeholder: 'Custom card holder',
      label: 'Card Holder Name',
    });
    cardHolderName.mount?.('#card-holder');

    const expirationMonth = container.createCollectElement({
      type: ElementType.EXPIRATION_MONTH,
      placeholder: 'Custom expiration month',
      label: 'Expiration Month',
    });
    expirationMonth.mount?.('#expiry-month');

    const expirationYear = container.createCollectElement({
      type: ElementType.EXPIRATION_YEAR,
      placeholder: 'Custom expiration year',
      label: 'Expiration Year',
    });

    expirationYear.mount?.('#expiry-year');

    const cvv = container.createCollectElement({
      type: ElementType.CVV,
      placeholder: 'Custom cvv',
      label: 'CVV',
    });

    cvv.mount?.('#security-code');

    document
      .querySelector('#tokenize-button')
      ?.addEventListener('click', async () => {
        const token = await container.collect();
        console.log('token', token);
        const tokenizations = await container.tokenize({
          storeInstrument: true,
        });
        console.log('tokenizations', tokenizations);
      });
  };

  return (
    <>
      <Card header="Dropin configuration">
        <div>
          <label>showStoreInstrumentCheckbox</label>
          <input
            type="checkbox"
            defaultChecked={dropinConfig.showStoreInstrumentCheckbox}
            onClick={(e) => {
              setDropinConfig({
                ...dropinConfig,
                showStoreInstrumentCheckbox: e.currentTarget.checked,
              });
            }}
          />
        </div>
        <div>
          <label>showCardHolderName</label>
          <input
            type="checkbox"
            onClick={(e) => {
              setDropinConfig({
                ...dropinConfig,
                showCardHolderName: e.currentTarget.checked,
              });
            }}
          />
        </div>
        <div>
          <label>showStoredInstruments</label>
          <input
            type="checkbox"
            defaultChecked={dropinConfig.showExistingCards}
            onClick={(e) => {
              setDropinConfig({
                ...dropinConfig,
                showExistingCards: e.currentTarget.checked,
              });
            }}
          />
        </div>
        <div>
          <label>Use custom translations in dropin</label>
          <input
            type="checkbox"
            onClick={(e) => {
              setDropinConfig({
                ...dropinConfig,
                customTranslations: e.currentTarget.checked,
              });
            }}
          />
        </div>

        <div>
          <button
            onClick={initDropin}
            disabled={Boolean(!payrailsClientId || !payrailsApiKey || !baseUrl)}
          >
            Initialize dropin
          </button>
          <button
            onClick={initPayrailsComponents}
            disabled={Boolean(!payrailsClientId || !payrailsApiKey || !baseUrl)}
          >
            Initialize Payrails elements
          </button>
          <button
            onClick={initSecureFields}
            disabled={Boolean(!payrailsClientId || !payrailsApiKey || !baseUrl)}
          >
            Initialize secure fields
          </button>
        </div>
      </Card>

      <InfoCard
        executionId={clientConfig?.execution.id}
        merchantReference={clientConfig?.execution.merchantReference}
      />

      {displayedMode === 'secure-fields' && (
        <Card header="Secure fields">
          <div id="secure-fields">
            <div id="card-holder"></div>
            <div id="card-number"></div>
            <div id="expiry-month"></div>
            <div id="expiry-year"></div>
            <div id="security-code"></div>
            <button id="tokenize-button">Tokenize</button>
          </div>
        </Card>
      )}

      {displayedMode === 'dropin' && (
        <Card header="Payrails dropin">
          <div id="dropin"></div>
        </Card>
      )}

      {displayedMode === 'components' && (
        <>
          <Card header="Card list element">
            <div id="card-list"></div>
          </Card>
          <Card header="Card form element">
            <div id="card-form"></div>
          </Card>
          <Card header="Card payment button">
            <div id="payment-button"></div>
          </Card>
          <Card header="Google pay button">
            <div id="google-pay-button"></div>
          </Card>
          <Card header={'Paypal button'}>
            <div id="paypal-button"></div>
          </Card>
        </>
      )}

      {clientConfig && (
        <RequestCard
          header={'Init config'}
          requestPayload={initConfig?.req}
          responsePayload={clientConfig}
        />
      )}
    </>
  );
};
