import { useEffect, useMemo, useState } from 'react';

import { authenticate } from '../../api/authentication';
import { confirmPayment } from '../../api/confirm-payment';
import { getExecution } from '../../api/get-execution';
import { Card } from '../../components/card';
import { useConfiguration } from '../../components/configuration';
import { InfoCard } from '../../components/info-card';
import { RequestCard } from '../../components/request-card';

export const DropinSuccess = () => {
  const [config] = useConfiguration();
  const [authentication, setAuthentication] = useState<{
    [key: string]: any;
  }>();
  const [confirm, setConfirm] = useState<{
    [key: string]: any;
  }>();
  const [execution, setExecution] = useState<{
    id: string;
    merchantReference: string;
  }>();
  const { payrailsApiKey, payrailsClientId, baseUrl } = config;

  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  useEffect(() => {
    (async () => {
      if (!baseUrl || !payrailsApiKey || !payrailsClientId) return;

      const req = await authenticate(payrailsClientId, payrailsApiKey, baseUrl);
      setAuthentication(req);
    })();

    return () => setAuthentication(undefined);
  }, [baseUrl, payrailsApiKey, payrailsClientId]);

  useEffect(() => {
    if (!authentication || confirm) return;

    (async () => {
      const confirmReq = await confirmPayment(
        `Bearer ${authentication.res.access_token}`,
        urlParams.get('executionId') as string,
        urlParams.get('redirectResult') as string,
        baseUrl
      );

      const confirmRes = await confirmReq.json();
      setConfirm(confirmRes);
    })();
  }, [authentication, baseUrl, confirm, urlParams]);

  useEffect(() => {
    if (!confirm || !authentication) return;

    (async () => {
      const execReq = await getExecution(
        `Bearer ${authentication.res.access_token}`,
        urlParams.get('executionId') as string,
        baseUrl,
        [
          'confirmRequested',
          'authorizeRequested',
          'authorizePending',
          'created',
        ]
      );

      const execRes = await execReq.json();

      setExecution(execRes);
    })();
  }, [confirm, payrailsApiKey, baseUrl, authentication, urlParams]);

  return (
    <>
      <Card header="">Payment completed!</Card>
      <InfoCard
        executionId={execution?.id}
        merchantReference={execution?.merchantReference}
      />
      {authentication && (
        <RequestCard
          header="Authentication"
          requestPayload={authentication.req}
          responsePayload={authentication.res}
          isCollapsed={true}
        />
      )}
      {confirm && (
        <RequestCard
          header="Confirm"
          responsePayload={confirm}
          isCollapsed={true}
        />
      )}
      {execution && (
        <RequestCard
          header="GET Execution"
          responsePayload={execution}
          isCollapsed={false}
        />
      )}
    </>
  );
};
