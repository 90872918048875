import { AuthenticationRequestResult } from '../../api/authentication';
import { RequestCard } from '../request-card';

interface Props {
  req?: AuthenticationRequestResult['req'];
  res?: AuthenticationRequestResult['res'];
  isCollapsed?: boolean;
}

export const AuthenticationCard = ({
  req,
  res,
  isCollapsed = false,
}: Props) => {
  if (req || res) {
    return (
      <RequestCard
        header="Authentication request log"
        requestPayload={req}
        responsePayload={res}
        isCollapsed={isCollapsed}
      />
    );
  }

  return <></>;
};
