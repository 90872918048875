import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { useConfiguration } from '../configuration';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export function ConfigQRCodeModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [qrCodeValue, setQRCodeValue] = useState('');
  const [config] = useConfiguration();

  useEffect(() => {
    const data = JSON.stringify(config);
    setQRCodeValue(data);
  }, [config]);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        contentLabel="QR Code"
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        ariaHideApp={false}
        style={modalStyles}
      >
        <div>
          <QRCodeSVG value={qrCodeValue} size={300} />
        </div>
      </Modal>
      <button onClick={() => setIsModalOpen(true)}> Show QR Code </button>
    </>
  );
}
