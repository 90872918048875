import { v4 } from 'uuid';

import { START_PAYMENT_SESSION_ADYEN_DROPIN } from '../example-payloads/start-payment-session';

import { buildUrl } from './http-utils';

export const startPaymentSession = (
  authHeader: string,
  executionId: string,
  baseUrl: string
) => {
  const url = buildUrl(
    baseUrl,
    `merchant/workflows/payment-acceptance/executions/${executionId}/startPaymentSession`
  );

  return fetch(url, {
    method: 'POST',
    headers: { authorization: authHeader, 'x-idempotency-key': v4() },
    body: JSON.stringify(START_PAYMENT_SESSION_ADYEN_DROPIN),
  });
};
