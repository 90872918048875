import './styles.css';

import { Link, NavLink } from 'react-router-dom';

import { HEADER_ROUTES } from '../../routes/router';

export const Header = () => (
  <h1 className="app-header">
    <Link to="/" className="header-text">
      <img src="./pr-logo.svg" alt="payrails-logo" />
      Payrails bootstrap
    </Link>

    <div className="header-links">
      {HEADER_ROUTES.map(({ title, path }, index) => (
        <HeaderLink title={title} to={path} key={`${index}`} />
      ))}
      <a
        href="https://github.com/payrails/bootstrap.payrails.io"
        target="_blank"
        rel="noreferrer"
      >
        <img src="./github-logo.png" alt="github repository"></img>
      </a>
    </div>
  </h1>
);
interface NavigationLinkProps {
  title: string;
  to?: string;
}
const HeaderLink = ({ title, to = '/' }: NavigationLinkProps) => (
  <NavLink to={to} className={({ isActive }) => (isActive ? 'active' : '')}>
    {title}
  </NavLink>
);
