export const START_PAYMENT_SESSION_ADYEN_DROPIN = {
  integrationType: 'adyenDropIn',
  amount: {
    value: '100',
    currency: 'EUR',
  },
  meta: {
    clientContext: {
      osType: 'ios',
    },
  },
};
