const cleanBaseUrl = (baseUrl: string) => {
  if (!baseUrl.endsWith('/')) {
    baseUrl += '/';
  }
  return baseUrl;
};

export const buildUrl = (
  baseUrl: string = process.env.BASE_URL as string,
  path: string
) => {
  return `${cleanBaseUrl(baseUrl)}${path}`;
};
