import '@adyen/adyen-web/dist/adyen.css';

import { useEffect, useState } from 'react';
import { PaymentMethodsResponseObject } from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types';

import { useConfiguration } from '../configuration';

import { createAdyenDropIn } from './create-adyen-dropin';

const DEFAULT_DROP_IN_ELEMENT_ID = 'dropin-container';

interface Props {
  elementId?: string;
  adyenClientKey: string;
  bearer: string;
  baseUrl: string;
  executionId: string;
  paymentMethodsResponse: PaymentMethodsResponseObject;
}

export const AdyenDropInThreeStep = ({
  elementId = DEFAULT_DROP_IN_ELEMENT_ID,
  adyenClientKey,
  paymentMethodsResponse,
  bearer,
  baseUrl,
  executionId,
}: Props) => {
  const [adyenDropIn, setAdyenDropIn] =
    useState<Awaited<ReturnType<typeof createAdyenDropIn>>>();
  const [{ useCVCForStoredCards, authorizationAmount }] = useConfiguration();

  useEffect(() => {
    (async () => {
      setAdyenDropIn(
        await createAdyenDropIn({
          amount: authorizationAmount,
          bearer,
          executionId,
          clientKey: adyenClientKey,
          paymentMethodsResponse,
          baseUrl,
          hideCVC: !useCVCForStoredCards,
        })
      );
    })();
  }, [
    bearer,
    executionId,
    adyenClientKey,
    paymentMethodsResponse,
    baseUrl,
    useCVCForStoredCards,
    authorizationAmount,
  ]);

  useEffect(() => {
    const el = document.getElementById(elementId);
    if (el && adyenDropIn) {
      adyenDropIn.mount(el);
    }

    return () => {
      adyenDropIn?.unmount();
    };
  }, [adyenDropIn, elementId]);

  return <div id={elementId}></div>;
};
