import { v4 } from 'uuid';

import { AUTHORIZE_WITH_GOOGLE_PAY } from '../example-payloads/authorize';

import { buildUrl } from './http-utils';

export const authorize = (
  authHeader: string,
  executionId: string,
  payload: any = AUTHORIZE_WITH_GOOGLE_PAY,
  baseUrl: string
) => {
  const url = buildUrl(
    baseUrl,
    `merchant/workflows/payment-acceptance/executions/${executionId}/authorize`
  );

  return fetch(url, {
    method: 'POST',
    headers: {
      'x-idempotency-key': v4(),
      authorization: authHeader,
    },
    body: JSON.stringify(payload),
  });
};
