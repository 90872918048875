import { createBrowserRouter } from 'react-router-dom';

import { Root } from '../components/root';

import { Api } from './api';
import { CSE } from './cse';
import { CustomCardForm } from './custom-card-form';
import { DropinSuccess } from './dropin-success';
import { ThreeStepDropinFlow } from './three-step-dropin-flow';
import { Tokenization } from './tokenization';

export const HEADER_ROUTES = [
  {
    index: true,
    title: 'API',
    element: <Api />,
  },

  {
    path: '/tokenization',
    title: 'Tokenization',
    element: <Tokenization />,
  },

  {
    path: '/cse',
    title: 'CSE',
    element: <CSE />,
  },
];

if (sessionStorage.getItem('testMode') === 'true') {
  HEADER_ROUTES.push({
    path: '/three-step-dropin',
    title: '3-Step dropin',
    element: <ThreeStepDropinFlow />,
  });
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      ...HEADER_ROUTES,
      {
        path: '/dropin-success',
        element: <DropinSuccess />,
      },
      {
        path: '/custom-card-form',
        element: <CustomCardForm />,
      },
    ],
  },
]);
