import { Outlet } from 'react-router';

import { ConfigurationProvider } from '../configuration';
import { Configuration } from '../configuration/component';
import { Header } from '../header';

export const Root = () => {
  return (
    <ConfigurationProvider>
      <div className="App">
        <Header />
        <Configuration />
        <div>
          <Outlet />
        </div>
      </div>
    </ConfigurationProvider>
  );
};
