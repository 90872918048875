import { v4 } from 'uuid';

import { CREATE_EXECUTION_PAYLOAD } from '../example-payloads/create-execution';

import { buildUrl } from './http-utils';
import { ApiEnvelope } from './types';

interface Req {
  method: string;
  headers: {
    'x-idempotency-key': string;
    authorization: string;
  };
  body: string;
}

interface Res<TInitResults = never[]> {
  id: string;
  merchantReference: string;
  holderReference: string;
  meta: {
    amount: {
      currency: string;
      value: string;
    };
    [key: string]: any;
  };
  initialResults: TInitResults;
}

export type CreateExecutionRequestResult<TInitResults = any[]> = ApiEnvelope<
  Req,
  Res<TInitResults>
>;

export const createExecution = async <TInitResults = any[]>(
  authHeader: string,
  payload: any = CREATE_EXECUTION_PAYLOAD,
  baseUrl: string
): Promise<CreateExecutionRequestResult<TInitResults>> => {
  try {
    const url = buildUrl(
      baseUrl,
      `merchant/workflows/payment-acceptance/executions`
    );
    const reqInit = {
      method: 'POST',
      headers: {
        'x-idempotency-key': v4(),
        authorization: authHeader,
      },
      body: JSON.stringify(payload),
    };

    const req = await fetch(url, reqInit);
    const res = await req.json();

    return {
      ok: req.ok,
      req: reqInit,
      res,
    };
  } catch (e) {
    console.log(e);
    throw new Error('Create execution request failed');
  }
};
