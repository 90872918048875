import './styles.css';

import { ReactNode, useState } from 'react';

interface Props {
  className?: string;
  children?: ReactNode;
  header: string;
  isCollapsed?: boolean;
  onCollapsed?: (isCollapsed: boolean) => void;
}

export const Card = ({
  className = '',
  children = '',
  header,
  isCollapsed = false,
  onCollapsed = () => undefined,
}: Props) => {
  const [collapsed, setCollapsed] = useState<boolean>(isCollapsed);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
    onCollapsed(!collapsed);
  };

  return (
    <div className={`card-component ${className} `}>
      {header && <h2>{header}</h2>}
      <div className={`content-wrapper ${collapsed ? 'collapsed' : ''}`}>
        {children}
      </div>

      {children !== '' && (
        <div className="toggleCollapse">
          <span onClick={toggleCollapse}>{collapsed ? 'show' : 'hide'}</span>
        </div>
      )}
    </div>
  );
};
