import { buildUrl } from './http-utils';
import { ApiEnvelope } from './types';

interface Req {
  url: string;
  method: string;
  headers: {
    'x-api-key': string;
  };
}

interface Res {
  access_token: string;
  expires_in: string;
  token_type: string;
}

export type AuthenticationRequestResult = ApiEnvelope<Req, Res>;

export const authenticate = async (
  clientId: string,
  apiKey: string,
  baseUrl: string
): Promise<AuthenticationRequestResult> => {
  const url = buildUrl(baseUrl, `auth/token/${clientId}`);
  const reqConfig = {
    method: 'POST',
    headers: {
      'x-api-key': apiKey,
    },
  };

  try {
    const req = await fetch(url, reqConfig);
    const res = await req.json();

    return {
      ok: req.ok,
      req: { url, ...reqConfig },
      res,
    };
  } catch (e) {
    console.log(e);
    throw new Error('Authentication failed');
  }
};
